<template>
<base-popup
    :popup-model="show"
    title="Generate Report of Broken Link"
    :valid="!valid"
    save-button-text="Generate"
    @onCancelButtonClick="closeHandler"
    @onSaveButtonClick="saveItemHandler"
>
  <div class="mt-5 mb-5">
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
    <g-date-selector
        @onChangeDateHandler="onChangeDateHandler"
        class="mr-2"
    />
    </v-form>
  </div>
</base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import GDateSelector from "../calendar/GDateSelector";
export default {
  name: "BrokenLinkPopup",
  mixins: [PopupMixin],
  components: {GDateSelector, BasePopup},
  methods: {
    closeHandler() {
      this.$emit('closePopupHandler');
    },
    onChangeDateHandler(selection) {
      const {startDate, endDate} = selection;
      this.currentItem.startDate = startDate;
      this.currentItem.endDate = endDate;
    }
  }
}
</script>

<style scoped>

</style>