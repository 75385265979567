<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        title="Broken List"
        icon="mdi-vector-link"
        add-button-text="Check Broken Link"
        add-button-icon="mdi-update"
        stateendpoint="brokenLink.brokenLinks"
        sort="orderId"
        :show-default-action-buttons="false"
        :show-clear-button="true"
        :popup-component="popupComponent"
        :show-add-button="false"
        :actions="actions"
        :action-buttons="actionButton"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import BrokenLinkPopup from "../components/popups/BrokenLinkPopup";

export default {
  name: 'BrokenLinks',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Broken Lists | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, BrokenLinkPopup},
  data() {
    return {
      headers: [
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Site URL',
          value: 'siteUrl'
        },
        {
          text: 'Live Link',
          value: 'liveLink'
        },
        {
          text: 'Status',
          value: 'paymentStatus'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      columns: [
        {value: 'invoiceNo'},
        {value: 'siteUrl'},
        {value: 'liveLink'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadBrokenLinks',
        restore: 'updateBrokenLink',
        destroy: 'destroyBrokenLink',
        clearData: 'clearBrokenLink'
      },
      actionButton: [
        {
          category: 'restore',
          icon: 'mdi-backup-restore',
          color: '',
          text: 'Restore Link',
          clickHandler: 'restoreHandler'
        },
        {
          category: 'delete',
          icon: 'mdi-delete',
          color: 'error',
          text: 'Fixed Manually',
          clickHandler: 'destroyItem'
        },
      ]
    }
  },
  computed: {
    popupComponent() {
      return BrokenLinkPopup;
    }
  }
}
</script>
